<!-- <div class="warli"  ></div> -->
<div class="footer_area bnr_ftr_overlay">
  <div class="holder">
    <div class="hr">
      <div class="vc-sd-8 vc-sd-offset-2">
        <div class="footer_area_top_inner">
          <div class="footer_text  align-center">
            <h2>Planning For A Vacation?</h2>
            <p>You can book your stay online, and if it is available that day, automatically reserve your stay
              to enjoy your vacation. So do not wait, book your resort now!</p>
          </div>
          <!-- <div class="footer_btn  align-center">
            <a href="booking/choose"  class="common_btn btn_hbreffect2">book now</a>
          </div> -->
        </div>
      </div>
      <div class="vc-sd-11 vc-sd-offset-1 vc-od-10 vc-od-offset-1 vc-ld-8 vc-ld-offset-2">
        <ul class="footer_contact">
          <li>
            <div class="footer-phone">
              <span><i class="fa fa-mobile"></i></span>
              <p><a href="tel:+{{token.getProperty().mobile}}">{{token.getProperty().mobile}}</a></p>

            </div>
          </li>
          <li>
            <div class="footer-mail">
              <span><i class="fa fa-map-marker"></i></span>
              <!-- <p>421/1617/2422, Bajrang Vihar, Patia, Bhubaneswar, Odisha 751024, India
                          </p> -->
              <p>{{token.getProperty().address.streetName}} {{token.getProperty().address.streetNumber}}
                {{token.getProperty().address.suburb}} {{token.getProperty().address.city}}
                {{token.getProperty().address.state}} {{token.getProperty().address.country}}
                {{token.getProperty().address.postcode}} </p>
              <!-- <p>{{property.address}}</p> -->

            </div>
          </li>
          <li>
            <div class="footer-info">
              <span><i class="fa fa-window-maximize"></i></span>
              <p><a href="mailto::resortdgreen@gmail.com">resortdgreen@gmail.com
                </a>
              </p>


            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer_area_bottom">
    <div class="holder">
      <div class="hr">
        <div class="vc-sd-6">
          <div class="copyright-right">
            <p> Made with ❤ by <a href="https://www.credencesoft.co.nz/"><img class="sh-image-url"
                  src="assets/images/cs-logo.png" alt="CredenceSoft-outline" /></a><br />
              COPYRIGHT @ 2022 ALL RIGHT RESERVED. D Green Village Resort <br> Powered
                By <a href="https://bookonepms.com"><img class="sh-image-url" src="assets/images/pms-logo.png" alt="CredenceSoft-outline" /></a>
            </p>
          </div>
        </div>
        <div class="vc-sd-6">
          <div class="footer_area_bottom_inner align-right">
            <div class="footer_menu">
              <!-- <button class="common_btn btn_hbreffect2 mr-3"><a href="https://app.bookonelocal.in/#/sessions/signin" target="_blank">Login to PMS</a></button> -->
              <nav>
                <ul>
                  <!-- <li><a href="booking.php">Booking</a></li>
                                      <li><a href="room2.php">rooms</a></li> -->
                  <!-- <li><a routerLink="/contact">contact</a></li> -->

                </ul>
              </nav>
            </div>
            <div class="footer_social_area">
              <ul class="contact-links">
                <li class="ml-3">
                  <a href="https://api.whatsapp.com/send/?phone=%2B{{token.getProperty().mobile}} &text&app_absent=0" target="_blank" title="">
                    <i class="front whatsapp fa fa-whatsapp"></i>
                    <i class="back front fa fa-whatsapp"></i>
                  </a>
                </li>
                <li *ngIf="token.getProperty().facebook" class="ml-3">
                  <a href="https://fb.com/{{ token.getProperty().facebook }}" target="_blank" title=""><i
                      class="front facebook fa fa-facebook"></i>
                    <i class="back fa fa-facebook"></i></a>
                </li>
                <li *ngIf="token.getProperty().instagram" class="ml-3">
                  <a href="https://instagram.com/{{ token.getProperty().instagram }}" target="_blank" title="">
                    <i class=" front instagram fa fa-instagram"></i>
                    <i class="back front fa fa-instagram"></i>
                  </a>
                </li>



                <!-- <li>
                                      <a href="#" target="_blank">
                                          <i class="front fa fa-pinterest-square"></i>
                                          <i class="back fa fa-pinterest-square"></i>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="#" target="_blank">
                                          <i class="front fa fa-skype"></i>
                                          <i class="back fa fa-skype"></i>
                                      </a>
                                  </li> -->

                <!-- <div id="TA_cdsratingsonlywide841" class="TA_cdsratingsonlywide">
                  <ul id="s8roTxK2hn" class="TA_links D12JEwAt1S7">
                    <li id="hFcgEd" class="zhBWN0op"><a target="_blank"
                        href="https://www.tripadvisor.com/Hotel_Review-g608474-d23733644-Reviews-Flora_Ecostay_Lonavala-Lonavala_Pune_District_Maharashtra.html"><img
                          src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg"
                          alt="TripAdvisor" /></a></li>
                  </ul>
                </div> -->


              </ul>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>
<a routerLink="/top" class="scroll_top" (click)="scroll_top()" data-smooth-scroll data-animate-time="500">
  <i class="fa fa-angle-double-up"></i>

</a>
