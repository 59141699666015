<!-- <div class="preloader">
  <div class="loader">
    <span class="inner-loader">
      <img *ngIf="property.logoUrl !== null && property.logoUrl !== undefined" [src]="property.logoUrl" alt="" class="logospin"/>
      <img *ngIf="property.logoUrl == null || property.logoUrl == undefined" src="assets/images/logo.jpeg" alt="" class="logospin"/>

    </span>
  </div>
</div> -->

<header class="navbar-fixed">
  <div class="toolbar-inner-area sticky-navbar" [ngClass]="{'sticky': sticky}" (scroll)="onElementScroll($event)" data-sticky-offset="50">
    <div class="holder">
      <div class="hr">
        <div class="vc">
          <div class="toolbar_contact align-right">
            <div class="pms_connection">
              <!-- <a href="booking/choose" class=" common_btn1 btn_hbreffect" style="margin-top: 8px; padding: 15px;">book now</a> -->

              <!-- <a href="https://bookonelocal.in/#/listing/d-green-village-resort?BookingEngine=true" ><button class="btn common_btn1 btn_hbreffect btnclas" >Order Now</button></a> -->
            </div>

            <div class="contact_phone">

              <a href="tel:+{{token.getProperty().mobile}}">{{token.getProperty().mobile}}</a>

            </div>
            <div class="toolbar_social_area">
              <ul class="icon_list">
                <li>
                  <a href="https://api.whatsapp.com/send/?phone=%2B{{token.getProperty().mobile}} &text&app_absent=0" target="_blank" title="">
                    <i class="fa fa-whatsapp"></i>
                  </a>
                </li>
                <li *ngIf="token.getProperty().facebook">
                  <a href="https://fb.com/{{ token.getProperty().facebook }}" target="_blank"
                  title="">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
               <li *ngIf="token.getProperty().instagram">
                  <a href="https://instagram.com/{{ token.getProperty().instagram }}" target="_blank" title="">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>

                <!-- <li>
                                  <a href=""><i class="fa fa-skype"></i></a>
                              </li>
                              <li>
                                  <a href=""><i class="fa fa-pinterest-square"></i></a>
                              </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-navbar></app-navbar>
</header>
