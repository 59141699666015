<!-- <ngx-slick-carousel class="carousel listing-slider mfp-gallery-container margin-bottom-0"
#slickModal="slick-carousel" >
<div ngxSlickItem *ngFor="let gallery of slideImage" class="slide">
  <img class="d-block w-100" height="650px" src="{{gallery.image}}" alt="First slide">

</div>
</ngx-slick-carousel> -->

<div class="banner_area_type1 banner_area overlapping-fix slider1">
  <div class="slider_overlay_area slider_height">
    <div class="holder slider_height">
      <div class="hr slider_height">
        <div class="vc-cd-12 vc-rd-8 vc-rd-offset-2 vc-sd-12 vc-ad-8 vc-ad-offset-2 slider_height vc-ad-">
          <div class="slider_inner_area slider_height">
            <div class="slider_inner_content align-center">
              <div class="section_title align-center">
                <div class="section_sub_title">
                  <p>D Green Village Resort</p>
                </div>
                <h1>Best memories starts here</h1>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <ngx-slick-carousel class="camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
    #slickModal="slick-carousel" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let gallery of slideImage" class="slide camera_src camerastarted ">
      <img class=" " src="{{gallery.imageUrl}}" alt="First slide">
      <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}" style="background-size: cover;"
        class="item mfp-gallery cover" title="{{gallery.title}}"></a>
    </div>
  </ngx-slick-carousel>

  <!-- <div class="camera_wrap " id="banner_slider1">
    <div class="hero_slider" *ngFor="let gallery of slideImage" data-src="{{gallery.imageUrl}}"></div>


  </div>-->
</div>

<!-- <ngx-slick-carousel class="carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
#slickModal="slick-carousel" [config]="slideConfig">
<div ngxSlickItem *ngFor="let gallery of slideImage" class="slide">
  <img class="d-block w-100" height="650px" src="{{gallery.imageUrl}}" alt="First slide">
  <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}" style="background-size: cover;"
    class="item mfp-gallery cover" title="{{gallery.title}}"></a>
</div>
</ngx-slick-carousel> -->
<!-- <div class="warli"  ></div> -->
