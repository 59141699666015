<div class="cmn_banner_area banner_type14 bnr_overlay2">
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p>Gallery</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc">
              <div class="bradecrumbe">
                  <ul class="breadcrumb justify-content-center justify-content-cd--center">
                      <li>
                          <a routerLink="/home">
                              Home
                          </a>
                      </li>
                      <li class="active">
                          Gallery
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
</div>


<div class="gallery_area_type1 ptb-70">
  <div class="holder">
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_filter align-center">
                  <!-- <ul class="filter" id="grid">
                      <li class="active" data-filter="*">
                          <span class="common_btn">All Photos</span>
                      </li>
                      <li data-filter=".rooms">
                          <span class="common_btn">Rooms</span>
                      </li>
                      <li data-filter=".restaurant" class="">
                          <span class="common_btn">Restaurant & Club</span>
                      </li>
                      <li data-filter=".hotel">
                          <span class="common_btn">Resort</span>
                      </li>
                  </ul> -->
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc-sd-12">
              <div class="gallery_item align-center">
                  <div class=" gallery_item1 row">
                      <div *ngFor="let gallery of galleryImage" class="col-3 grid-item width-1-4 {{gallery.class}}" >
                          <div class="item_content">
                              <div class="single_grid_item">
                                  <img src="{{gallery.imageURL}}" alt="gallery" >
                                  <a routerLink="{{gallery.imageURL}}" class="singel_item_hover_content restaurant_menu_image" data-gall="smg">
                                      <h5>{{gallery.title}}</h5>

                                  </a>
                              </div>
                          </div>
                      </div>


                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

