<div class="banner_area_type3 bnr_overlay2">
    <div class="holder">
        <div class="hr">
            <div class="vc">
                <div class="banner_section_title align-center">
                    <div class="section_sub_title">
                        <p>about us</p>
                    </div>
                    <h1>A Home Away From Home</h1>
                </div>
            </div>
        </div>
        <div class="hr">
            <div class="vc">
                <div class="bradecrumbe">
                    <ul class="breadcrumb justify-content-center justify-content-cd--center">
                        <li>
                            <a routerLink='/'>
                                Home
                            </a>
                        </li>
                        <li class="active">
                            About Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="holder pt-5">
    <div class="row">
        <div class="col-md-6 mb-2 margin_top " >
            <ngx-slick-carousel
            class="camera_wrap carousel listing-slider mfp-gallery-container margin-bottom-0 hero_slider"
            #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let gallery of slideImage"
                class="slide camera_src camerastarted ">
                <img class=" " src="{{gallery.imageUrl}}"  alt="First slide">
                <!-- <a [ngStyle]="{'background-image': 'url(' + gallery.imageUrl + ')'}"
                    style="background-size: cover;" class="item mfp-gallery cover"
                    title="{{gallery.title}}"></a> -->
            </div>
        </ngx-slick-carousel>
        </div>

        <div class="col-md-6 mb-5">
            <div class="overview_text_inner align-center">
                <div class="section_title align-center">

                    <h1>D Green Village Resort Solapur, Akkalkot</h1>
                </div>
                <div class="section_body">
                    <p>D Green Village Resort is a modern and eco-friendly resort that has affordable and budget
                        friendly accommodation options for Family, Couple & Groups. A unique lodging
                        facility that takes steps to reduce its carbon footprint while giving back to its
                        local community. We provide UV treated water in all the rooms in glass bottle
                        keeping to minimize the plastic usage. We also attempt to digitizes all our
                        operations so that minimum paper usage in our day-day operations to save the trees.
                        All the reservations come with complimentary breakfast options , also other
                        delicious local cuisine meal packages that can be chosen at an extra cost. Most of
                        our food items are being prepared from locally sourced fresh vegetables. We are very passionate about giving an authentic taste to the foods we
                        prepare. A friendly and welcoming staff, who always thrive to ensure our Guests
                        have lovely and memorable staying experiences. We call it “ A Home Away From Home”
                        experience when the Guest leaves us with great feedback.
                    </p>
                </div>
            </div>
        </div>


    </div>
    </div>
