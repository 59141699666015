<div class="overview_area secondary-bg-color ptb-100">
  <div class="" style="padding: 50px;">
    <div class="row col-lg-12 " style="background-color: white; display: flex; flex-wrap:wrap; padding: 50px;">
      <div class="col-md-6 slider_animation" >
        <video controls autoplay width="100%"  class="wow fadeInLeft" data-wow-delay="0.3s">
          <source src="assets\images\vedio.mp4" type="video/mp4"  >
        </video>
        <!-- <img style="height: 342pxpx; width: 500px;" src="assets/images/service2.jpeg" alt="overview"> -->
      </div>
      <div class="col-md-6">
        <!-- <p>Economical and Ecological</p> -->
        <h1>Relax In Our Resort</h1>

        <!-- <div class="section_body"> -->
        <p>D Green Village Resort is a modern and eco-friendly resort that has affordable and budget friendly
          accommodation options for Family, Couple & Groups. A unique lodging facility that takes steps to reduce
          its carbon footprint while giving back to its local community. We provide UV treated water in all the
          rooms in glass bottle keeping to minimise the plastic usage. We also attempt to digitise all our
          operations so that minimum paper usage in our day-day operations to save the trees. All the reservations
          come with complimentary breakfast options , also other delicious local cuisine meal packages that can be
          chosen at an extra cost. Most of our food items are being prepared from locally sourced fresh vegetables. We are very passionate about giving an authentic taste to the foods we prepare. A friendly
          and welcoming staff , who always thrive to ensure our Guests have lovely and memorable staying
          experiences. We call it “ A Home Away From Home” experience when the Guest leaves us with great feedback.
        </p>
        <a routerLink="/aboutus" class="common_btn2 btn_hbreffect1">about us</a>
        </div>
      <!-- </div> -->
      <!-- <div class="holder">
    <div class="hr">
        <div class="vc-od-6 vc-sd-6"> -->
      <!-- <div class="overview_img_inner">
                <div class="overview_img2 overview_img">
                    <img src="assets/images/review_img3.jpg" alt="overview">
                </div>
                <div class="overview_img3 overview_img">
                    <img src="assets/images/review_img1.jpg" alt="overview">
                </div>
            </div> -->
      <!-- <div>
              <div class="overview_img3 overview_img">
                <img src="assets/images/service2.jpeg" alt="overview">
            </div> -->
      <!-- <video width="100%" height="" controls muted >
                  <source src="assets\images\video.mp4" type="video/mp4">
                </video> -->
      <!-- </div>
        </div>
        <div class="vc-od-5 vc-od-offset-1 vc-sd-6">
            <div class="overview_text_inner align-center">
                <div class="section_title align-center">
                    <div class="section_sub_title">
                        <p>Economical and Ecological</p>
                    </div>
                    <h1>Relax In Our Resort</h1>
                </div>
                <div class="section_body">
                    <p>D Green Village Resort is a modern and eco-friendly resort that has affordable and budget friendly accommodation options for Family, Couple & Groups. A unique lodging facility that takes steps to reduce its carbon footprint while giving back to its local community.  We provide UV treated water in all the rooms in glass bottle keeping to minimise the plastic usage. We also attempt to digitise all our operations so that minimum paper usage in our day-day operations to save the trees. All the reservations come with complimentary breakfast options , also other delicious local cuisine meal packages that can be chosen at an extra cost. Most of our food items are being prepared from locally sourced fresh vegetables. We are very passionate about giving an authentic taste to the foods we prepare. A friendly and welcoming staff , who always thrive to ensure our Guests have lovely and memorable staying experiences. We call it “ A Home Away From Home” experience when the Guest  leaves us with great feedback.</p>





                    <a routerLink="/aboutus" class="common_btn2 btn_hbreffect1">about us</a>
                </div>
            </div>
        </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>
